<!--  -->
<template>
  <div class="mains">
    <!-- 导航栏 -->
    <Navigation />

    <div class="productImg">
      <img :src="bannerImg" />
      <div class="newsEnglish">PRODUCT&nbsp;CATEGORY</div>
      <div class="newsChinese">产品系列</div>
    </div>

    <div class="content">
      <!-- 宠物系列 -->
      <div class="same friendShip" id="affection">
        <div class="titleImg">
          <img src="../../assets/img/product/Petpersonality.jpg" alt="" />
        </div>
        <div class="left mobileTop">
          <img :src="friendImg" alt="" />
        </div>
        <div class="right mobileBottom">
          <div class="detail">
            <div class="Title friendTitle">{{$globalVar.menu2_c_1}}</div>
            <div class="Title-E friendE">{{ $globalVar.menu2_c_1_desc }}</div>
            <div class="DetailText friendDetail">
              <!-- 快意之事莫若友，快友之事莫若谈，友谊犹如一幅长长的画卷，不因时间的阻隔而冲淡，不因岁月的漫长而改变，竭诚相助亲密无间。多少笑声都是友谊唤起的，多少眼泪都是友谊擦干的。友谊的港湾温情脉脉，友谊的清风灌满征帆。朋友就像是你生命中的一盏灯，在你最需要温暖的时候给你送来温暖，在你最颓废的时候给你勇气。“君子之交淡如水”，因为淡所以才能不腻，才能持久。 -->
              <div class="content">
                {{ $globalVar.menu2_c_1_content }}
              </div>
              <!-- <div class="expect">敬请期待...</div> -->
              <div class="link_btn">
                <router-link to="/product-pet" class="toDetails"
              >查看详情</router-link
            >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 情侣系列 -->
      <div class="affection" id="DesignConcept">
        <div class="leftContent mobileBottom">
          <div class="Title">{{$globalVar.menu2_c_title2}}</div>
          <div class="Title-E titleEnglish">FAMILY SERIES</div>
          <div class="DetailText affectionText">
            <!-- 血浓于水，恩重如山。每个人一生下来，就拥有一种习以为常,那就是亲情，亲情如水，使纷繁经过过滤变得纯净；亲情似火，使平淡通过煅烧日显棱角，DNA是我们之间的纽带，让我们相心相系，坚不可摧。如果说，人生是一个五味瓶，那么亲情就是最甜的一个；
            如果说人生是一幅画，那么亲情就是最绚丽的一笔。 -->
            <div class="content">
              {{ $globalVar.menu2_c_2_content }}
            </div>
            <div class="link_btn">
                <router-link to="/product-love" class="toDetails"
              >查看详情</router-link
            >
              </div>
          </div>
        </div>
        <div class="rightContent mobileTop">
          <img :src="affectionImg" alt="" />
        </div>
      </div>

      <!-- <div class="separate"></div> -->
    </div>
  </div>
</template>

<script>
import Navigation from "../../components/Navigation.vue"; // 导航组件
import Swiper from "swiper";

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      bannerImg: require("@/assets/img/product/banner.jpg"), // 产品展示大图
      loveSeriesImg: require("@/assets/img/product/loveSeries.png"), // 爱情系列的背景图
      loveImg: require("@/assets/img/product/love.jpg"), // 爱人相吻图片
      carbonChainImg: require("@/assets/img/product/concept.jpg"), // 基因碳链图片
      affectionImg: require("@/assets/img/product/affection.jpg"), // 亲情图片
      friendImg: require("@/assets/img/product/friend.jpg"), // 友情图片
      LoveSeries: [
        require("@/assets/img/product/ima00.jpg"),
        require("@/assets/img/product/ima01.jpg"),
        require("@/assets/img/product/ima03.jpg"),
        require("@/assets/img/product/ima04.jpg"),
        require("@/assets/img/product/ima05.jpg"),
      ],
    };
  },
  computed: {
    // 上一张
    prevIndex() {
      if (this.currentIndex == 0) {
        return this.LoveSeries.length - 1;
      } else {
        return this.currentIndex - 1;
      }
    },
    // 下一张
    nextIndex() {
      if (this.currentIndex == this.LoveSeries.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    },
  },
  created() {
    this.$bus.$on("sendMsg", (data) => {
      if (data) {
        // //创建时执行跳转锚点位置
        this.$nextTick(() => {
          this.getlocal();
        });
      }
    });
  },
  methods: {
    //锚点跳转从我本地找到id
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.getElementById(selectId);
      //如果对应id存在，就跳转
      if (selectId) {
        // console.log(toElement, "toElement");
        toElement.scrollIntoView(true);
      }
    },
  },
  mounted() {
    // if (window.screen.width >= 1024) {
    //   this.bannerImg = require("@/assets/img/product/banner.jpg");
    //   this.loveSeriesImg = require("@/assets/img/product/loveSeries.png"); // 爱情系列的背景图
    //   this.loveImg = require("@/assets/img/product/love.jpg"); // 爱人相吻图片
    //   this.carbonChainImg = require("@/assets/img/product/concept.jpg"); // 基因碳链图片
    //   this.affectionImg = require("@/assets/img/product/affection.jpg"); // 亲情图片
    //   this.friendImg = require("@/assets/img/product/friend.jpg"); // 友情图片
    // } else {
    //   this.bannerImg = require("@/assets/img/h5/product/banner.png");
    //   this.loveSeriesImg = require("@/assets/img/h5/product/loveSeries.jpg");
    //   this.loveImg = require("@/assets/img/h5/product/love.png");
    //   this.carbonChainImg = require("@/assets/img/h5/product/carbonChain.png");
    //   this.affectionImg = require("@/assets/img/h5/product/affection.png"); // 亲情图片
    //   this.friendImg = require("@/assets/img/h5/product/friend.jpg"); // 亲情图片
    // }

    // 滑动块
    new Swiper(".mySwiper", {
      loop: true,
      autoplay: true,
      spaceBetween: 30,
      centeredSlides: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".rightFlip",
        prevEl: ".leftFlip",
      },
    });
  },
};
</script>

<style lang="less" scoped>
.mains {
  width: 100%;
  background-color: #fff;

  .productImg {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .newsEnglish {
      font-size: 0.185rem;
      font-weight: 400;
      letter-spacing: 0.08rem;
      position: absolute;
      top: 1.37rem;
      left: 3.5rem;
      color: rgba(255, 255, 255, 1);
    }
    .newsChinese {
      font-size: 0.2rem;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 1.74rem;
      left: 4.55rem;
      letter-spacing: 0.08rem;
    }
  }

  @media (min-width: 320px) and (max-width: 1023px) {
    .productImg {
      display: none;

      .newsChinese,
      .newsEnglish {
        display: none;
      }
    }

    // 主要内容
    .content {
      position: relative;

      // 中文小标题
      .Title-C {
        font-size: 0.5rem;
        font-weight: bold;
        color: #333333;
      }
      // 英文小标题
      .Title-E {
        opacity: 0.5;
        color: #000000;
        font-size: 0.34rem;
        font-weight: 500;
        padding: 0.2rem 0 0.15rem 0;
      }
      // 详细描述的文字
      .DetailText {
        text-align: left;
        font-size: 0.28rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.53rem;
        padding: 0.23rem 0;
        .content{
          text-indent: 2em;
        }
      }
      // 敬请期待
      .expect {
        text-align: center;
        padding-top: 0.52rem;
        font-size: 0.34rem;
        font-weight: 500;
        opacity: 0.5;
      }

      // 爱情系列
      .loveSeries {
        position: relative;
        padding-top: 0.6rem;
        .titleImg {
          position: absolute;
          z-index: 50;
          left: 1.63rem;
          img {
            height: 1.3rem;
          }
        }
        .loveImg {
          display: inline-block;
          padding: 0 0.3rem;
          .love {
            position: relative;
            top: 1rem;
            left: 0;
            z-index: 100;
            img {
              width: 5.19rem;
              height: 5.17rem;
            }
          }
          .hugBruin {
            width: 3.42rem;
            height: 3.49rem;
            position: relative;
            top: -2.85rem;
            left: 3.55rem;
            z-index: 200;
            .handle {
              width: 1.72rem;
              height: 0.84rem;
              float: right;
              border: 0.02rem solid rgb(239, 239, 239);
              .leftFlip {
                width: 49%;
                border-right: 0.02rem solid rgb(239, 239, 239);
              }
              .rightFlip {
                width: 49%;
              }
              .leftFlip,
              .rightFlip {
                height: 0.84rem;
                display: inline-block;
                text-align: center;
                line-height: 0.84rem;
              }
            }
            .hugBruin-Img-Box {
              width: 3.42rem;
              height: 3.49rem;
              overflow: hidden;
              .hugBruin-Img {
                width: 3.42rem;
                height: 3.49rem;
              }
            }
          }
        }
        // 文字内容
        .loveExplain {
          .Title {
            display: none;
          }
          width: 6.85rem;
          margin: 0 auto;
          text-align: center;
          margin-top: -1.2rem;
          .toDetails {
            color: #ce9d6c;
            text-align: center;
            font-size: 0.34rem;
            font-weight: 500;
            opacity: 0.7;
          }
        }
      }

      // 亲情系列
      .affection {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 0.5rem;
        .mobileTop {
          img {
            width: 7.5rem;
            height: 7.55rem;
          }
        }
        .mobileBottom {
          .Title {
            font-size: 0.49rem;
          }
          background: url("../../assets/img/h5/product/affectionBg.png")
            no-repeat;
          background-size: 7.5rem 8.12rem;
          color: #ffffff;
          padding: 0.9rem 0.3rem;
          text-align: center;
          .titleEnglish,
          .affectionText {
            color: #ffffff;
            opacity: 1;
          }
        }
      }

      .separate {
        height: 0.2rem;
        background-color: #f3f2f2;
      }

      // 友情系列
      .friendShip {
        .titleImg {
          display: none;
        }
        display: flex;
        flex-direction: column;
        .mobileTop {
          img {
            width: 7.5rem;
            height: 7.53rem;
          }
        }
        .mobileBottom {
          padding: 0.7rem 0.25rem 0.8rem;
          text-align: center;
          .Title {
            font-size: 0.49rem;
          }
        }
      }
    }
  }

  @media (min-width: 760px) and (max-width: 1023px) {
    // 主要内容
    .content {
      // 中文小标题
      .Title-C {
        font-size: 0.4rem;
      }
      // 英文小标题
      .Title-E {
        font-size: 0.27rem;
      }
      // 详细描述的文字
      .DetailText {
        font-size: 0.205rem;
      }
      // 敬请期待
      .expect {
        font-size: 0.22rem;
      }

      // 爱情系列
      .loveSeries {
        // 文字内容
        .loveExplain {
          .toDetails {
            font-size: 0.22rem;
          }
        }
      }

      // 亲情系列
      .affection {
        .mobileBottom {
          .Title {
            font-size: 0.4rem;
          }
        }
      }

      // 友情系列
      .friendShip {
        .mobileBottom {
          .Title {
            font-size: 0.4rem;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    // 主要内容
    .content {
      position: relative;
      .same {
        width: 79.5%;
        margin: 0 auto;
      }
      // 系列标题
      .Title {
        font-size: 0.31rem;
        font-weight: bold;
        margin-left: -0.07rem;
      }
      // 中文小标题
      .Title-C {
        font-size: 0.22rem;
        font-weight: bold;
      }
      // 英文小标题
      .Title-E {
        opacity: 0.5;
        font-size: 0.1354rem;
        font-weight: 500;
        padding: 0.14rem 0 0.22rem 0.02rem;
      }
      // 详细描述的文字
      .DetailText {
        text-align: left;
        font-size: 0.1145rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.19rem;
        .content{
          text-indent: 2em;
        }
      }
      // 敬请期待
      .expect {
        padding-top: 0.35rem;
        font-size: 0.1562rem;
        font-weight: 500;
        opacity: 0.5;
      }

      // 爱情系列
      .loveSeries {
        height: 4.3rem;
        padding: 0.28rem 0 0.45rem 0;
        .titleImg {
          text-align: right;
          position: relative;
          z-index: 50;
          img {
            width: 6.979rem;
            height: 0.8125rem;
          }
        }
        .loveImg {
          display: inline-block;
          .love {
            position: relative;
            top: -0.227rem;
            left: 0;
            z-index: 100;
            img {
              width: 3.3541rem;
              height: 3.34375rem;
            }
          }
          .hugBruin {
            width: 2.2135rem;
            height: 2.2552rem;
            position: relative;
            top: -2.8rem;
            left: 2.284rem;
            z-index: 200;
            .handle {
              width: 1.1093rem;
              height: 0.5521rem;
              border: 0.0169rem solid rgb(239, 239, 239);
              float: right;
              display: flex;
              .leftFlip {
                border-right: 0.0169rem solid rgb(239, 239, 239);
              }
              .leftFlip,
              .rightFlip {
                flex: 1;
                display: inline-block;
                height: 0.5521rem;
                text-align: center;
                line-height: 00.5521rem;
              }
              .leftFlip img:hover,
              .rightFlip img:hover {
                cursor: pointer;
              }
            }
            .hugBruin-Img-Box {
              width: 2.2135rem;
              height: 2.552rem;
              overflow: hidden;
              .hugBruin-Img {
                width: 2.2135rem;
                height: 2.2552rem;
              }
            }
          }
        }
        .loveExplain {
          float: right;
          width: 2.17rem;
          margin: 0.2rem 0.7rem 0 0;
          .loveTitle {
            margin-left: -0.025rem;
            color: #111111;
          }
          .loveProduct {
            .nameC {
              color: #333333;
              margin-top: 0.45rem;
            }
            .nameE {
              color: #000000;
            }
            .loveText {
              width: 2.158rem;
              margin: 0.14rem 0 0.15rem;
            }
            .toDetails {
              color: #ce9d6c;
              font-size: 0.1562rem;
              font-weight: 500;
              opacity: 0.7;
            }
          }
        }
      }

      // 亲情系列
      .affection {
        margin: 0.45rem 0 0rem 0;
        padding-bottom: 0.2rem;
        height: 4.229rem;
        background: url("../../assets/img/product/affectionBg.jpg") no-repeat;
        background-size: 100% 4.229rem;
        position: relative;
        .leftContent {
          display: inline-block;
          width: 2.7084rem;
          color: #ffffff;
          margin: 0.85rem 0 0 1.85rem;
          .titleEnglish {
            opacity: 1;
            padding-left: 0;
          }
          .affectionText {
            color: #fff;
            margin: 0.1rem 0 0.3rem;
          }
        }
        .rightContent {
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
          img {
            width: 4.427rem;
            height: 4.229rem;
          }
        }
      }

      // 友情系列
      .friendShip {
        padding: 0.35rem 0 1.3rem;
        position: relative;
        .titleImg {
          text-align: right;
          position: relative;
          top: 0;
          right: -0.375rem;
          z-index: 50;
          img {
            width: 6.85rem;
            height: 0.8125rem;
          }
        }
        .left {
          display: inline-block;
          position: relative;
          top: -0.06rem;
          z-index: 200;
          img {
            width: 4.1302rem;
            height: 4.125rem;
          }
        }
        .right {
          width: 4.1302rem;
          height: 4.125rem;
          display: inline-block;
          position: absolute;
          top: 1.6rem;
          right: 0;
          background-image: url("../../assets/img/product/friendBg.png");
          .detail {
            padding: 0.6rem 0 0 1.3rem;
            .friendTitle,
            .friendE {
              color: #000000;
            }
            .friendE {
              margin-left: -0.06rem;
            }
            .friendDetail {
              width: 2.521rem;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1680px) {
    // 主要内容
    .content {
      position: relative;
      .same {
        width: 80%;
        margin: 0 auto;
      }
      // 系列标题
      .Title {
        font-size: 0.235rem;
        font-weight: bold;
        margin-left: 0;
      }
      // 中文小标题
      .Title-C {
        font-size: 0.18rem;
        font-weight: bold;
      }
      // 英文小标题
      .Title-E {
        opacity: 0.5;
        font-size: 0.15rem;
        font-weight: 500;
        padding: 0.14rem 0 0.22rem 0.02rem;
      }
      // 详细描述的文字
      .DetailText {
        text-align: left;
        font-size: 0.13rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.23rem;
      }
      // 敬请期待
      .expect {
        padding-top: 0.35rem;
        font-size: 0.13rem;
        font-weight: 500;
        opacity: 0.5;
      }

      // 爱情系列
      .loveSeries {
        .loveExplain {
          margin-right: 0.25rem;
          .loveProduct {
            .nameC {
              margin-top: 0.5rem;
            }
            .loveText {
              width: 2.4rem;
              margin-top: 0.1rem;
            }
            .toDetails {
              color: #359efe;
              font-size: 0.13rem;
              font-weight: 500;
              opacity: 0.7;
            }
          }
        }
      }

      // 亲情系列
      .affection {
        .leftContent {
          width: 2.9rem;
          margin: 0.5rem 0 0 1rem;
        }
        .rightContent {
          img {
            width: 4.4rem;
            height: 4.229rem;
          }
        }
      }

      // 友情系列
      .friendShip {
        .left {
          img {
            width: 4.1rem;
            height: 4.125rem;
          }
        }
        .right {
          .detail {
            padding: 0.5rem 0 0 1.2rem;
            .friendTitle,
            .friendE {
              color: #000000;
            }
            .friendE {
              margin-left: 0;
            }
            .friendDetail {
              width: 2.8rem;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    // 主要内容
    .content {
      // 系列标题
      .Title {
        font-size: 0.21rem;
        margin-left: 0;
      }
      // 中文小标题
      .Title-C {
        font-size: 0.155rem;
      }
      // 英文小标题
      .Title-E {
        font-size: 0.13rem;
      }
      // 详细描述的文字
      .DetailText {
        font-size: 0.11rem;
        line-height: 0.24rem;
      }
      // 敬请期待
      .expect {
        font-size: 0.11rem;
      }

      // 爱情系列
      .loveSeries {
        .loveExplain {
          .loveProduct {
            .loveText {
              width: 2.2rem;
            }
            .toDetails {
              font-size: 0.11rem;
            }
          }
        }
      }

      .friendShip {
        .right {
          .detail {
            .friendE {
              margin-left: -0.03rem;
            }
          }
        }
      }
    }
  }
}


.link_btn{
  margin-top: 20px;
  a {
    color:rgb(0, 119, 255);
  }
}



</style>
